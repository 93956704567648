.icons{
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}
.icons .about{
    display: flex;
    width: 250px;
    gap: 25px;
    align-items: center;
}
.icons .about img{
    width: 50px;
}
.icons .about p{
    color: gray;
    font-size: 14px;
}
.icons .about h4{
    font-size: 14px;
}


@media screen and (max-width:550px){
    .icons{
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
    .icons .about{
        display: flex;
        width: 250px;
        gap: 10px;
        align-items: center;
    }
    .icons .about img{
    }
}