@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200&display=swap');
*{
  margin:0;
  padding:0;
  box-sizing:border-box;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  list-style: none;
}

.container{
  margin-left: 15px;
  margin-right: 15px;
}
