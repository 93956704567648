.news{
    height: 75vh;
    margin-top: 100px;
}
.news-head{
    text-align: center;
}
.news-head h1{
    font-size: 45px;
}
.news-head h5{
    font-size: 18px;
    font-weight: normal;
    text-transform: capitalize;
    color: gray;
}
.news-body{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-items: baseline;
    justify-items: center;
    grid-gap: 25px;
}
.news-item{
    display: flex;
    flex-direction: column;
    width: 375px;
    height: auto;
    row-gap: 20px;
    margin-top: 50px;
}
.news-item .news-img{
  width: 375px;
}
.news-item .news-img img{
    width: 100%;
    border-radius: 25px;
}
.news-sub-head,.date,.news-img{
    cursor: pointer;
}
.date{
    color: gray;
}
.news-sub-head h4{
    font-size: 18px;
}
.content{
    color: gray;
}

@media screen and (max-width:550px){
    .news-body{
        display: grid;
        grid-template-columns:1fr;
        justify-items: baseline;
        justify-items: center;
        grid-gap: 25px;
    }
}