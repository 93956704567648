.nav-bar{
    height: 80px;
    /* background: gold; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}
.logo{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.logo img{
    cursor: pointer;
}
.nav-item{
    display: flex;
    gap: 30px;
}
.nav-item li{
    cursor: pointer;
    font-size: 18px;
    color: gray;
}
.nav-item .fa-circle-xmark{
    display: none;
}
.nav-item li:hover{
    color: rgb(0, 0, 0);
}
.nav-bar .right-nav{
    display: flex;
    gap: 25px;
    font-size: 20px;
}
.nav-bar .right-nav i{
    cursor: pointer;
    color: gray;
}
.nav-bar .right-nav i:hover{
    color: rgb(0, 0, 0);
}
.nav-bar .right-nav  .fa-bars-staggered{
    display: none;
}
.search{
    position:fixed;
    width: 100%;
    height:100%;
    top: 0;
    left: 0;
    background: rgb(240, 240, 240);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}
.search.active{
    display: flex;
    transition: 1s ease-in-out;
}
.search .search-bar{
    background: #fff;
    width: 50%;
    height: 10%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search .search-bar input{
    width: 90%;
    font-size: 30px;
    outline: rgb(0, 0, 0);
    border: none;
    outline: none;
}
.search .search-bar i{
    font-size: 30px;
    cursor: pointer;
    color: gray;
}
.close-icon{
    position: absolute;
    font-size: 50px;
    font-weight:500;
    top: 0;
    cursor: pointer;
}
.close-icon:hover i{
    color: #000;
}
.close-icon i{
    color: gray;
}


@media screen and (max-width:550px){
    .nav-bar{
        display: none;
    }
   
}