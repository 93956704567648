.copyright{
    background: #000;
    margin-top: 50px;
}
.copy{
    height: 80px;
    color: white;
    font-size: 16px;
    font-weight: lighter;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px;
}
.right-txt{
    cursor: pointer;
}