.category{
    height:auto;
    margin-top: 25px;
}
.category .top{
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 25px;
    text-align: left;
}
.category .top .card{
    height:325px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;
}
.category .top .card h2{
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(255, 255, 255);
    opacity: 0.4;
}
.card1{
    background: rgb(27, 27, 27);
    color: #fff;
}
.card2{
    background: rgb(244, 198, 14);
    color: #fff;
}
.category .top .card h4{
    font-size: 20px;
    font-weight: normal;
    text-transform: capitalize;
}
.category .top .card h3{
    font-size: 25px;
    text-transform: capitalize;
}
.card3{
    background: rgb(228, 53, 53);
    color: #fff;
}
.card1 h2{
    font-size: 45px;
}
.card2 h2{
    font-size: 45px;
}
.card3 h2{
    font-size: 45px;
}
.card1,.card2,.card3{
    padding-left: 5%;
}
.card .btn span{
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    background: rgb(255, 255, 255);
    border-radius:25px ;
    color: #000;
    cursor: pointer;
}
.card .btn span:hover{
    background: rgb(255, 212, 18);
    border-radius:25px ;
    color: #000;
}
.category .top .card .image{
    position: absolute;
    width:300px;
    right: -50px;
    top: 0;
    bottom: 0;
}
.category .top .card .image img{
    width:100%;
}
.card3 .image{
    width:500px !important;
    right: -100px !important;
    top:-0px !important;
} 


/*************************************/

.category .bottom{
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 25px;
    text-align: left;
    margin-top: 25px;
}

.category .bottom .cards{
    height:325px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-top: 10%;
    position: relative;
    overflow: hidden;
}
.category .bottom .cards h2{
    text-transform: uppercase;
    font-weight: 900;
    color: rgb(255, 255, 255);
    opacity: 0.4;
}
.category .bottom .card4 h2{
    opacity: 0.9 !important;
}
.card6 h2{
    font-size: 45px;
}
.card5 h2{
    font-size: 45px;
}
.card4 h2{
    font-size: 45px;
}
.card4 h4,.card4 h3{
    color: #000;
}
.card1,.card2,.card3{
    padding-left: 5%;
}
.category .bottom .cards h4{
    font-size: 20px;
    font-weight: normal;
    text-transform: capitalize;
}
.category .bottom .cards h3{
    font-size: 25px;
    text-transform: capitalize;
}
.card4{
    background: rgb(231, 231, 231);
    color: #fff;
}
.card5{
    background: rgb(8, 221, 104);
    color: #fff;
}
.card6{
    background: rgb(53, 155, 228);
    color: #fff;
}

.category .bottom .card5 .image{
    position: absolute;
    width:300px;
    right: -50px;
    bottom: 0px;
    line-height: 0;
}
.category .bottom .cards .image img{
    width:100%;
}
.category .bottom .card4 .image{
    position: absolute;
    width:250px;
    right: -0px;
    bottom: -0px;
    line-height: 0;
}
.cards .btn span{
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    background: rgb(255, 255, 255);
    border-radius:25px ;
    color: #000;
    cursor: pointer;
} 

@media screen and (max-width:550px){
    .category .top{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 25px;
        text-align: left;
    }
    .category .bottom{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 25px;
        text-align: left;
    }
    .card3 .image{
        width:350px !important;
        right: -100px !important;
        top:-0px !important;
    } 
}